import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      <>
        {/* FOOTER SECTION */}
        <section className="float-left w-100 position-relative footer-con main-box white-bg-con">
          <div className="container">
            <div className="row newsletter1">
              <div className="col-xl-12 mr-auto ml-auto">
                <div className="subscribe-newsletter-con d-flex align-items-center justify-content-around">
                  <h4 className="black-text font-weight-bold mb-0">
                    SNP PAY - Empowering Indian Retailers
                  </h4>
                  {/* subscribe newsletter con */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="footer-inner-links">
                  <h4 className="blue-text">Services</h4>
                  <ul className="list-unstyled p-0 m-0">
                    <li>
                      <a href="/our_services" className="d-inline-block">
                        Mobile Recharge
                      </a>
                    </li>
                    <li>
                      <a href="/our_services" className="d-inline-block">
                        DTH Recharge
                      </a>
                    </li>
                    <li>
                      <a href="/our_services" className="d-inline-block">
                        Google Play Recharge
                      </a>
                    </li>
                    <li>
                      <a href="/our_services" className="d-inline-block">
                        Utility Bill Payments
                      </a>
                    </li>
                    <li className="mb-0">
                      <a href="/our_services" className="d-inline-block">
                        PAN Card Service
                      </a>
                    </li>
                    {/* list unstyled */}
                  </ul>
                  {/* footer inner links */}
                </div>
                {/* col */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="footer-inner-links">
                  <h4 className="blue-text">Quick Links</h4>
                  <ul className="list-unstyled p-0 m-0">
                    <li>
                      <a href="/" className="d-inline-block">
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/about_us" className="d-inline-block">
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="/contact_us" className="d-inline-block">
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a
                        href="/terms_and_conditions"
                        className="d-inline-block"
                      >
                        Terms and Conditions
                      </a>
                    </li>
                    <li>
                      <a href="/privacy_policy" className="d-inline-block">
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="/refund_policy" className="d-inline-block">
                        Refund Policy
                      </a>
                    </li>
                    <li className="mb-0">
                      <a href="/grievance_policy" className="d-inline-block">
                        Grievance Policy
                      </a>
                    </li>
                    {/* list unstyled */}
                  </ul>
                  {/* footer inner links */}
                </div>
                {/* col */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="footer-inner-links var4">
                  <h4 className="blue-text">Get In Touch</h4>
                  <ul className="list-unstyled p-0 mb-0">
                    <li className="position-relative">
                      <img
                        src="assets/images/location-purple-icon.png"
                        alt="icon"
                        className="img-fluid"
                      />{" "}
                      <a href="https://maps.app.goo.gl/mEqCDFN2wNtjrVYn6">
                        SNP Payments Solution, Hadipur, Deganga, <br /> North 24
                        Parganas,West Bengal - 700126
                      </a>
                    </li>
                    <li className="position-relative">
                      <img
                        src="assets/images/mail-purple-icon.png"
                        alt="icon"
                        className="img-fluid"
                      />
                      <a href="mailto:help.snppay@gmail.com">
                        help.snppay@gmail.com
                      </a>
                    </li>
                    <li className="position-relative mb-0">
                      <img
                        src="assets/images/fon-purple-icon.png"
                        alt="icon"
                        className="img-fluid"
                      />
                      <a href="tel:919735386181">
                        +91 9735-3861-81 / 0321-6383-642
                      </a>
                    </li>
                  </ul>
                  {/* footer inner links */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            <div className="copyright-con float-left w-100 position-relative d-flex align-items-center justify-content-between">
              <div className="footer-logo-con">
                <a href="index-2.html">
                  <figure>
                    <img
                      src="/images/logo.png"
                      alt="logo"
                      className="img-fluid"
                      width={200}
                    />
                  </figure>
                </a>
                {/* footer logo con */}
              </div>
              <div className="copyright-content">
                <p className="mb-0">© 2024 SNP PAY. All Rights Reserved.</p>
                {/* copyright content */}
              </div>
              <ul className="list-unstyled p-0 m-0 d-flex align-items-center social-icon mb-0">
                <li>
                  <a href="https://www.facebook.com/login/">
                    <i className="fa-brands fa-facebook-f ml-0" />
                  </a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/i/flow/login">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/" className="mr-0">
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
              </ul>
              {/* copyright con */}
            </div>
            {/* container */}
          </div>
          {/* footer con  */}
        </section>
        {/* Latest compiled JavaScript */}
        <div id="sidebar-cart-curtain" />
      </>
    </React.Fragment>
  );
};

export default Footer;
