import React from "react";

const Faq = () => {
  return (
    <React.Fragment>
      <>
        {/* QUESTION ANSWERS SECTION */}
        <section className="float-left w-100 position-relative question-answer-con padding-top padding-bottom main-box">
          <div
            className="container wow fadeIn"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="question-con">
                  <div className="heading-title-con">
                    <span className="special-text sea-green-text d-block">
                      Frequently Asked Questions
                    </span>
                    <h2 className="special text-white">
                      We've got the answers <br />
                      you need.
                    </h2>
                    <p className="text-white">
                      All the Answers You Need to Get Started and Succeed with
                      SNP PAY.
                    </p>
                    {/* heading title con */}
                  </div>
                  <img
                    src="/images/faq.png"
                    alt="image"
                    className="img-fluid"
                  />
                  {/* question con */}
                </div>
                {/* col */}
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="faq">
                  <div className="accordian-section-inner position-relative">
                    <div className="accordian-inner">
                      <div id="domain_accordion1">
                        <div className="accordion-card">
                          <div className="card-header" id="headingOne">
                            <a
                              href="#"
                              className="btn btn-link"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="false"
                              aria-controls="collapseOne"
                            >
                              <h5>
                                What services does SNP PAY offer for retailers?
                              </h5>
                            </a>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse show"
                            aria-labelledby="headingOne"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                SNP PAY provides a range of digital payment
                                solutions for retailers, including mobile
                                recharges, DTH services, Google Play recharge,
                                utility bill payments, and PAN card services.
                                All services are designed to be fast, secure,
                                and easy to use, helping you serve your
                                customers more efficiently.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-card">
                          <div className="card-header" id="headingTwo">
                            <a
                              href="#"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              <h5>
                                How can I become a retailer partner with SNP
                                PAY?
                              </h5>
                            </a>
                          </div>
                          <div
                            id="collapseTwo"
                            className="collapse"
                            aria-labelledby="headingTwo"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                Becoming a retailer partner with SNP PAY is
                                easy. Simply sign up on our platform, complete
                                the registration process, and you’ll gain
                                instant access to our services. Our team will
                                guide you through the process to ensure a smooth
                                onboarding experience.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-card">
                          <div className="card-header" id="headingThree">
                            <a
                              href="#"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              <h5>How do I earn from SNP PAY services?</h5>
                            </a>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                Retailers earn by offering mobile recharges, DTH
                                services, bill payments, and PAN card services
                                to their customers. You’ll earn a commission for
                                each transaction completed. The more
                                transactions you process, the more you can earn.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-card">
                          <div className="card-header" id="headingFive">
                            <a
                              href="#"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseFive"
                              aria-expanded="false"
                              aria-controls="collapseFive"
                            >
                              <h5>Is SNP PAY’s platform secure? </h5>
                            </a>
                          </div>
                          <div
                            id="collapseFive"
                            className="collapse"
                            aria-labelledby="headingFive"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                Yes, SNP PAY prioritizes the security of all
                                transactions. Our platform is built with
                                industry-standard encryption and security
                                protocols to ensure that both you and your
                                customers have a safe and secure experience.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-card">
                          <div className="card-header" id="headingSix">
                            <a
                              href="#"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              <h5>
                                How quickly can I start offering services?
                              </h5>
                            </a>
                          </div>
                          <div
                            id="collapseSix"
                            className="collapse"
                            aria-labelledby="headingSix"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                Once you complete the sign-up process and your
                                account is activated, you can start offering
                                services immediately. Our platform is designed
                                for instant access, so you can begin earning
                                from day one.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-card mb-0">
                          <div className="card-header" id="headingsevn">
                            <a
                              href="#"
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseSevn"
                              aria-expanded="false"
                              aria-controls="collapseSevn"
                            >
                              <h5>How can I contact SNP PAY support?</h5>
                            </a>
                          </div>
                          <div
                            id="collapseSevn"
                            className="collapse"
                            aria-labelledby="headingsevn"
                            data-parent="#domain_accordion1"
                          >
                            <div className="card-body">
                              <p className="text-size-16 text-left mb-0">
                                You can reach our support team via email at
                                help.snppay@gmail.com or through our customer
                                service hotline at +91 9735-3861-81 /
                                0321-6383-642. We are always here to assist you
                                with any queries or issues.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* domain acordin card */}
                      </div>
                      {/* accordian inner */}
                    </div>
                    {/* accordian section inner */}
                  </div>
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* question answer con */}
        </section>
      </>
    </React.Fragment>
  );
};

export default Faq;
