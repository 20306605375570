import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";
import WhyChooseUs from "../Home/WhyChooseUs";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - SNP PAY";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <section className="float-left w-100 position-relative sub-banner-con reseller-banner main-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="sub-banner-content">
                  <h1>About Us</h1>
                  <p className="sub-text">Our Commitment, Your Satisfaction.</p>
                  <div className="breadcrumb-con d-inline-block">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        About Us
                      </li>
                    </ol>
                  </div>
                  {/* sub baner content */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* sub banner con */}
        </section>
        <>
          {/* EMPOWERING YOUR DIGITAL JOURNEY SECTION */}
          <section className="float-left w-100 position-relative padding-top padding-bottom hosting-features-con main-box background-f3f3f3 empowering-journy-con">
            <div
              className="container wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="heading-title-con text-center">
                <span className="special-text purple-text d-block">
                  Who We Are
                </span>
                <h2>Welcome to SNP PAY</h2>
                {/* heading title con */}
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-9 pl-0">
                  <div className="white-box">
                    <p className="mb-0 text-justify">
                      Welcome to SNP PAY, your one-stop solution for fast,
                      secure, and reliable digital payments. As a brand under
                      SNP PAYMENTS SOLUTION, we are dedicated to simplifying
                      financial transactions for individuals and businesses
                      alike. Our platform offers a comprehensive range of
                      services, including mobile recharge, DTH recharge, Google
                      Play recharge, PAN card services, and utility bill
                      payments.
                      <br /> <br />
                      We are committed to delivering innovative solutions that
                      make digital payments seamless and accessible to everyone.
                      With a focus on security, efficiency, and user
                      convenience, SNP PAY is designed to enhance your payment
                      experience, empowering you to manage essential services
                      with ease.
                      <br /> <br />
                      Driven by technology and guided by a customer-centric
                      approach, we continuously strive to build trust and foster
                      long-term relationships with our users. Whether you're
                      recharging your phone, paying bills, or applying for a PAN
                      card, SNP PAY is here to make every transaction smooth and
                      effortless.
                    </p>
                    {/* white box */}
                  </div>
                  {/* col */}
                </div>

                <div className="row  pt-4">
                  <div className="col-lg-6 col-md-6">
                    <div className="feature-box background-fff d-flex">
                      <figure>
                        <img src="assets/images/feature-icon1.png" alt="icon" />
                      </figure>
                      <div className="feature-content">
                        <h4>Our Mission</h4>
                        <p className="mb-0">
                          To simplify digital payments through innovation,
                          security, and customer-focused solutions, empowering
                          individuals and businesses.
                        </p>
                        {/* feature content */}
                      </div>
                      {/* feature box */}
                    </div>
                    {/* col */}
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="feature-box background-fff d-flex">
                      <figure>
                        <img src="assets/images/feature-icon4.png" alt="icon" />
                      </figure>
                      <div className="feature-content">
                        <h4>Our Vision</h4>
                        <p className="mb-0">
                          To be a leader in digital payments, enabling seamless,
                          secure, and inclusive financial transactions for all.
                        </p>
                        {/* feature content */}
                      </div>
                      {/* feature box */}
                    </div>
                    {/* col */}
                  </div>
                </div>

                {/* row */}
              </div>
              {/* container */}
            </div>
            {/* hosting features con */}
          </section>

          <WhyChooseUs />
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
