import React from "react";

const AboutCompany = () => {
  return (
    <React.Fragment>
      {/* App-Solution-Section-Start */}

      <section
        className="float-left w-100 position-relative perfect-domain-name-con padding-top padding-bottom main-box"
        id="features"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="appie-features-9-area-thumb">
                <img
                  src="/images/recharge-banner.png"
                  alt="about-us"
                  width={550}
                />
              </div>
            </div>
            <div className="col-xl-6 text-center text-xl-left pt-5 pt-xl-0">
              <div className="appie-features-9-area-heading">
                <h2>About SNP PAY</h2>
              </div>
              <p className="text-justify">
                Welcome to SNP PAY, your one-stop solution for fast, secure, and
                reliable digital payments. As a brand under SNP PAYMENTS
                SOLUTION, we are dedicated to simplifying financial transactions
                for individuals and businesses alike. Our platform offers a
                comprehensive range of services, including mobile recharge, DTH
                recharge, Google Play recharge, PAN card services, and utility
                bill payments.
              </p>
              <p className="text-justify pt-2 pb-4">
                We are committed to delivering innovative solutions that make
                digital payments seamless and accessible to everyone. With a
                focus on security, efficiency, and user convenience, SNP PAY is
                designed to enhance your payment experience, empowering you to
                manage essential services with ease.
              </p>
              <a href="/about_us">
                <div class="primary-button d-inline-block">
                  <button type="submit" id="submit">
                    Read More
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* App-Solution-Section-end */}
    </React.Fragment>
  );
};

export default AboutCompany;
