import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const StartHero = () => {
  return (
    <React.Fragment>
      <>
        {/* BANNER SECTION */}
        <section className="float-left w-100 position-relative banner-con main-box">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 p-xl-0 p-lg-0 p-md-0 order-xl-0 order-lg-0 order-md-0 order-1">
                <div className="banner-content-con text-left">
                  <h1 className="text-white">
                    {" "}
                    <span className="d-inline-block">
                      Welcome to SNP PAY
                    </span>{" "}
                    <br />
                    Empowering Indian Retailers
                  </h1>
                  <p className="text-white font-weight-light">
                    Experience the ease of mobile recharges, DTH recharge,
                    utility bill payments, and PAN card services with SNP PAY.
                    Instant, secure, and always available.
                  </p>
                  <div className="primary-button d-inline-block">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.fispl.snppay"
                      target="_blank"
                      className="d-inline-block"
                    >
                      Download App Now
                    </a>
                  </div>
                  {/* banner content con */}
                </div>
                {/* col */}
              </div>
              <div className="col-lg-6 col-md-6 pl-5">
                <div className="banner-img-con">
                  <img
                    src="/images/recharge-banner-2.png"
                    alt="image"
                    className=""
                    width={600}
                  />

                  {/* banner img con */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* banner con */}
        </section>
      </>
    </React.Fragment>
  );
};

export default StartHero;
