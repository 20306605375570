import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";
import ServicesBoxIcon from "./ServicesBoxIcon";
import WhyChooseUs from "../Home/WhyChooseUs";

const OurServices = () => {
  useEffect(() => {
    document.title = "Our Services - SNP PAY";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <section className="float-left w-100 position-relative sub-banner-con reseller-banner main-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="sub-banner-content">
                  <h1>Our Services</h1>
                  <p className="sub-text">
                    Empowering You with Seamless and Secure Payment Solutions
                  </p>
                  <div className="breadcrumb-con d-inline-block">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Our Services
                      </li>
                    </ol>
                  </div>
                  {/* sub baner content */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* sub banner con */}
        </section>

        <ServicesBoxIcon />

        <WhyChooseUs />

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default OurServices;
