import React from "react";

import "./style.css";

const ServicesBoxIcon = () => {
  return (
    <>
      {/* HOSTING FEATURES SECTION */}
      <section className="float-left w-100 position-relative padding-top padding-bottom hosting-features-con main-box background-f3f3f3">
        <div
          className="container wow fadeIn"
          data-wow-duration="2s"
          data-wow-delay="0.3s"
        >
          <div className="heading-title-con text-center">
            <span className="special-text purple-text d-block">
              Empowering You with Seamless and Secure Payment Solutions
            </span>
            <h2>Our Services</h2>
            {/* heading title con */}
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="feature-box background-fff d-flex">
                <figure>
                  <img
                    src="/images/mobile-recharge-icon.png"
                    width={60}
                    alt="icon"
                  />
                </figure>
                <div className="feature-content ">
                  <h4>Mobile Recharge</h4>
                  <p className="mb-0">
                    Keep your website online and accessible with our
                    industry-leading uptime guarantee. Your visitors will always
                    find you available.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              <div className="feature-box background-fff d-flex">
                <figure>
                  <img src="/images/dish-tv-icon.png" width={60} alt="icon" />
                </figure>
                <div className="feature-content">
                  <h4>DTH Recharge</h4>
                  <p className="mb-0">
                    Our hosting plans grow with your business. Upgrade or
                    downgrade your plan effortlessly as your needs change.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              <div className="feature-box background-fff d-flex mb-0">
                <figure>
                  <img
                    src="/images/google-play-icon.png"
                    width={60}
                    alt="icon"
                  />
                </figure>
                <div className="feature-content">
                  <h4>Google Play Recharge</h4>
                  <p className="mb-0">
                    Enjoy professional email hosting with your domain. Enhance
                    your brand image with custom email addresses.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              {/* col */}
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="feature-box background-fff d-flex">
                <figure>
                  <img
                    src="/images/electricity-bill-icon.png"
                    width={60}
                    alt="icon"
                  />
                </figure>
                <div className="feature-content">
                  <h4>Utility Bill Payments</h4>
                  <p className="mb-0">
                    Create a stunning website with ease using our intuitive,
                    drag-and-drop website builder. No coding skills required.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              <div className="feature-box background-fff d-flex">
                <figure>
                  <img src="/images/pan-card-icon.png" width={60} alt="icon" />
                </figure>
                <div className="feature-content">
                  <h4>PAN Card Service</h4>
                  <p className="mb-0">
                    Get round-the-clock assistance from our expert support team.
                    We're here to help you anytime, anywhere.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              <div className="feature-box background-fff d-flex mb-0">
                <figure>
                  <img src="/images/pan-card-icon.png" width={60} alt="icon" />
                </figure>
                <div className="feature-content">
                  <h4>Paperless PAN Card Service</h4>
                  <p className="mb-0">
                    Protect your data with advanced security features, including
                    free SSL certificates, daily backups, and DDoS protection.
                  </p>
                  {/* feature content */}
                </div>
                {/* feature box */}
              </div>
              {/* col */}
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* hosting features con */}
      </section>
    </>

    // <section
    //   className="appie-service-area appie-service-3-area pt-90 pb-50"
    //   id="service"
    // >
    //   <div className="container">
    //     <div className="row justify-content-center">
    //       <div className="col-lg-6">
    //         <div className="appie-section-title text-center">
    //           <h3 className="appie-title">Our Services</h3>
    //           Explore a wide range of recharge and payment solutions designed to
    //           <br />
    //           meet all your digital needs with speed, security, and convenience.
    //         </div>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div class="row" style={{ paddingTop: "15px" }}>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fas fa-mobile"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">Mobile Recharge</h3>
    //             <p class="description">
    //                offers quick, secure mobile recharges for all
    //               major telecom operators in India, ensuring seamless
    //               connectivity anytime, anywhere.
    //             </p>
    //           </div>
    //         </div>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fas fa-satellite-dish"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">DTH Recharge</h3>
    //             <p class="description">
    //                provides fast and easy DTH recharges for all
    //               major service providers, ensuring uninterrupted entertainment
    //               at your convenience.
    //             </p>
    //           </div>
    //         </div>
    //         <div class="col-md-4 col-sm-6">
    //           <div class="serviceBox blue">
    //             <div class="service-icon">
    //               <span>
    //                 <i class="fab fa-google-play"></i>
    //               </span>
    //             </div>
    //             <h3 class="title">Google Play Recharge</h3>
    //             <p class="description">
    //                offers quick and secure Google Play recharges,
    //               giving you instant access to apps, games, subscriptions, and
    //               more, anytime you need.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default ServicesBoxIcon;
