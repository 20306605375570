import React from "react";

const WhyChooseUs = () => {
  return (
    <React.Fragment>
      {/* WHY CHOOSE US SECTION */}
      <section className="float-left w-100 position-relative why-choose-us-con padding-top padding-bottom main-box text-center purple-con">
        <div
          className="container wow fadeInUp"
          data-wow-duration="2s"
          data-wow-delay="0.3s"
        >
          <div className="heading-title-con text-center">
            <span className="special-text sea-green-text d-block">
              Why Choose Us{" "}
            </span>
            <h2 className="text-white">Discover what sets SNP PAY apart:</h2>
            {/* heading title con */}
          </div>
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4 d-flex">
              <div className="white-box background-fff position-relative w-100">
                <figure>
                  <img
                    src="assets/images/choose-icon1.png"
                    alt="icon"
                    className="img-fluid"
                  />
                </figure>
                <h4 className="font-weight-bold mb-0">
                  Secure <br />
                  Transactions
                </h4>
                {/* white box */}
              </div>
              {/* col */}
            </div>
            <div className="col-lg-4 col-md-4 d-flex">
              <div className="white-box background-fff position-relative w-100">
                <figure>
                  <img
                    src="assets/images/choose-icon2.png"
                    alt="icon"
                    className="img-fluid"
                  />
                </figure>
                <h4 className="font-weight-bold mb-0">
                  User-Friendly <br />
                  Platform
                </h4>
                {/* white box */}
              </div>
              {/* col */}
            </div>
            <div className="col-lg-4 col-md-4 d-flex">
              <div className="white-box background-fff position-relative w-100">
                <figure>
                  <img
                    src="assets/images/choose-icon3.png"
                    alt="icon"
                    className="img-fluid"
                  />
                </figure>
                <h4 className="font-weight-bold mb-0">
                  24/7 Priority <br />
                  Support
                </h4>
                {/* white box */}
              </div>
              {/* col */}
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* why choose us con  */}
      </section>
    </React.Fragment>
  );
};

export default WhyChooseUs;
