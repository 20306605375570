import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";
import "./style.css";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const GrievancePolicy = () => {
  useEffect(() => {
    document.title = "Grievance Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <section className="float-left w-100 position-relative sub-banner-con reseller-banner main-box">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="sub-banner-content">
                  <h1>Grievance Policy</h1>
                  <p className="sub-text"></p>
                  <div className="breadcrumb-con d-inline-block">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <a href="/">Home</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Grievance Policy
                      </li>
                    </ol>
                  </div>
                  {/* sub baner content */}
                </div>
                {/* col */}
              </div>
              {/* row */}
            </div>
            {/* container */}
          </div>
          {/* sub banner con */}
        </section>

        <>
          {/* Blog Details Block */}
          <section class="float-left w-100 position-relative perfect-domain-name-con padding-top padding-bottom main-box">
            <div class="container">
              <div className="section_title">
                <h4>Grievance Policy for SNP PAY</h4>

                <div className="info pt-3">
                  <p>
                    In accordance with Information Technology Act 2000 and rules
                    made there under, contact details of the Grievance Officer
                    are provided below:
                  </p>
                  {/* <p>Name:</p> */}
                  <p className="pt-2">
                    Address: SNP Payments Solution, Hadipur, Deganga, North 24
                    Parganas,West Bengal - 700126
                  </p>
                  {/* <p>Mobile:</p> */}
                  <p className="pt-2">Email: help.snppay@gmail.com</p>
                </div>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GrievancePolicy;
