import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - SNP PAY";
  }, []);

  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    if (fname == "") {
      Swal.fire("Opps!", "Please Enter First Name", "error");
      return;
    }

    if (lname == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (email == "") {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (mobile == "") {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (subject == "") {
      Swal.fire("Opps!", "Please Enter Subject", "error");
      return;
    }

    if (message == "") {
      Swal.fire("Opps!", "Please Enter Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        fname + " " + lname,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        <>
          {/* SUB BANNER SECTION */}
          <section className="float-left w-100 position-relative sub-banner-con contact-banner main-box">
            <div className="container">
              <div className="row">
                <div className="col-lg-7 col-md-7">
                  <div className="sub-banner-content">
                    <h1>Contact Us</h1>
                    <p className="sub-text">
                      Have questions or need assistance? We're here to help!
                    </p>
                    <div className="breadcrumb-con d-inline-block">
                      <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                          <a href="/">Home</a>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page"
                        >
                          Contact
                        </li>
                      </ol>
                    </div>
                    {/* sub baner content */}
                  </div>
                  {/* col */}
                </div>
                {/* row */}
              </div>
              {/* container */}
            </div>
            {/* sub banner con */}
          </section>
          {/* CONTACT HELP SECTION */}
          <section className="float-left w-100 position-relative contact-help-con padding-top padding-bottom main-box text-center background-f3f3f3">
            <div
              className="container wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="heading-title-con text-center">
                <span className="special-text purple-text d-block">
                  Contact Us{" "}
                </span>
                <h2 className="">We’re Here to Help </h2>
                {/* heading title con */}
              </div>
              <div className="row align-items-center">
                <div className="col-lg-4 col-md-4 d-flex">
                  <div className="white-box background-fff position-relative w-100">
                    <figure>
                      <img
                        src="assets/images/location-img.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="font-weight-bold">Registered Office</h4>
                    <ul className="list-unstyled p-0">
                      <li>
                        SNP Payments Solution, Hadipur, Deganga, North 24
                        Parganas, West Bengal - 700126
                      </li>
                    </ul>
                    {/* white box */}
                  </div>
                  {/* col */}
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                  <div className="white-box background-fff position-relative w-100">
                    <figure>
                      <img
                        src="assets/images/email-img.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="font-weight-bold">Email</h4>
                    <ul className="list-unstyled p-0">
                      <li>
                        <a href="mailto: help.snppay@gmail.com">
                          help.snppay@gmail.com
                        </a>
                      </li>
                      <li>-</li>
                      <li>-</li>
                    </ul>
                    {/* white box */}
                  </div>
                  {/* col */}
                </div>
                <div className="col-lg-4 col-md-4 d-flex">
                  <div className="white-box background-fff position-relative w-100">
                    <figure>
                      <img
                        src="assets/images/phone-img.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </figure>
                    <h4 className="font-weight-bold">Phone</h4>
                    <ul className="list-unstyled p-0">
                      <li>
                        <a href="tel:=919735386181">+91 9735-3861-81</a>
                      </li>
                      <li>
                        <a href="tel:03216383642">0321-6383-642</a>
                      </li>
                      <li>-</li>
                    </ul>
                    {/* white box */}
                  </div>
                  {/* col */}
                </div>
                {/* row */}
              </div>
              {/* container */}
            </div>
            {/* why choose us con  */}
          </section>
          {/* CONTACT FORM SECTION*/}
          <section className="float-left w-100 position-relative contact-form-con padding-top padding-bottom main-box">
            <div
              className="container wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
            >
              <div className="heading-title-con text-center">
                <span className="special-text sea-green-text d-block">
                  Contact Form{" "}
                </span>
                <h2 className="text-white">Get in Touch With Us </h2>
                {/* heading title con */}
              </div>
              <div className="row">
                <div className="col-xl-10 col-lg-10 mr-auto ml-auto">
                  <form
                    className="main-form text-center"
                    method="post"
                    id="contactpage"
                  >
                    <ul className="list-unstyled p-0 float-left w-100 mb-0">
                      <li>
                        <input
                          type="text"
                          placeholder="First Name"
                          name="fname"
                          id="fname"
                        />
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="lname"
                          id="lname"
                        />
                      </li>
                      <li>
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          id="email"
                        />
                      </li>
                      <li>
                        <input
                          type="tel"
                          placeholder="Phone"
                          name="phone"
                          id="phone"
                        />
                      </li>
                      <li>
                        <textarea
                          placeholder="Message"
                          rows={6}
                          name="msg"
                          defaultValue={""}
                        />
                      </li>
                    </ul>
                    <div className="primary-button d-inline-block">
                      <button type="button" id="button">
                        Send Message
                      </button>
                    </div>
                  </form>
                  {/* col */}
                </div>
                {/* row */}
              </div>
              {/* container */}
            </div>
            {/* contact form con */}
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
