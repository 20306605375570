import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";

  const [headerTypeCss, setHeaderTypeCss] = useState(
    "header-nav-box header-nav-box-6"
  );

  useEffect(() => {
    if (headerType)
      setHeaderTypeCss(
        "header-nav-box header-nav-box-inner-page header-nav-box-3"
      );
  }, [headerType]);

  // if (headerType == "white_header") {
  //   setHeaderTypeCss(
  //     "header-nav-box header-nav-box-inner-page header-nav-box-3"
  //   );
  // }
  return (
    <React.Fragment>
      <>
        {/* LOADER */}
        {/* <div className="loader-mask">
          <div className="loader">
            <div />
            <div />
          </div>
        </div> */}
        {/* TOP BAR SECTION */}
        <div className="w-100 float-left top-bar-con main-box">
          <div className="wrapper1545">
            <div className="top-bar-inner-con d-flex align-items-center justify-content-between position-relative">
              <div className="left-con">
                <a
                  href="mailto:help.snppay@gmail.com"
                  className="text-white ml-0"
                >
                  {" "}
                  <img
                    src="assets/images/email-icon.png"
                    alt="icon"
                    className="img-fluid"
                  />{" "}
                  help.snppay@gmail.com
                </a>
                <a href="tel:919735386181" className="text-white">
                  {" "}
                  <img
                    src="assets/images/phone-icon.png"
                    alt="icon"
                    className="img-fluid"
                  />{" "}
                  +91 9735-3861-81 / 0321-6383-642
                </a>
                <a className="text-white mr-0">
                  {" "}
                  <img
                    src="assets/images/time-icon.png"
                    alt="icon"
                    className="img-fluid"
                  />
                  09.00 AM - 09:00 PM
                </a>
                {/* left con */}
              </div>
              <div className="right-con">
                <ul className="list-unstyled p-0 m-0 d-flex align-items-center social-icon mb-0">
                  <li>
                    <a href="https://www.facebook.com/login/" className="ml-0">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/">
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/i/flow/login">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" className="mr-0">
                      <i className="fa-brands fa-youtube" />
                    </a>
                  </li>
                </ul>
                <a
                  href="https://wa.me/919735386181?text=Hi Team, I need some help."
                  target="_blank"
                  className="d-inline-block text-white live-chat"
                >
                  {" "}
                  <img
                    src="assets/images/live-chat-icon.png"
                    alt="icon"
                    className="img-fluid"
                  />{" "}
                  <span className="d-inline-block text-uppercase text-white">
                    {" "}
                    Live Chat
                  </span>
                </a>
                {/* right con */}
              </div>
              {/* top bar inner con */}
            </div>
            {/* container */}
          </div>
          {/* top bar con */}
        </div>
        <div className="clearfix" />
        {/* HEADER SECTION */}
        <header className="w-100 flaot-left header-con position-relative main-box">
          <div className="wrapper1545">
            <nav className="navbar navbar-expand-lg navbar-light p-0">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
                <span className="navbar-toggler-icon" />
              </button>
              <a className="navbar-brand" href="index-2.html">
                <figure className="mb-0">
                  <img
                    src="/images/logo.png"
                    alt="logo-icon"
                    className="img-fluid"
                    width={220}
                  />
                </figure>
              </a>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown active">
                    <a className="nav-link p-0" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0" href="/about_us">
                      About Us
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle p-0"
                      href="#"
                      id="navbarDropdown2"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Our Services
                    </a>
                    <div
                      className="dropdown-menu custom-dropdown"
                      aria-labelledby="navbarDropdown2"
                    >
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a href="/our_services">
                            <i className="fa-solid fa-check" /> Mobile Recharge
                          </a>
                        </li>
                        <li>
                          <a href="/our_services">
                            <i className="fa-solid fa-check" /> DTH Recharge
                          </a>
                        </li>
                        <li>
                          <a href="/our_services">
                            <i className="fa-solid fa-check" /> Google Play Code
                          </a>
                        </li>
                        <li>
                          <a href="/our_services">
                            <i className="fa-solid fa-check" /> Utility Bill
                            Payments
                          </a>
                        </li>
                        <li>
                          <a href="/our_services">
                            <i className="fa-solid fa-check" /> PAN Card Service
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle p-0"
                      href="#"
                      id="navbarDropdown3"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Pages
                    </a>
                    <div
                      className="dropdown-menu custom-dropdown"
                      aria-labelledby="navbarDropdown3"
                    >
                      <ul className="list-unstyled mb-0">
                        <li>
                          <a href="/terms_and_conditions">
                            <i className="fa-solid fa-check" /> Terms and
                            Conditions
                          </a>
                        </li>
                        <li>
                          <a href="/privacy_policy">
                            <i className="fa-solid fa-check" /> Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a href="/refund_policy">
                            <i className="fa-solid fa-check" /> Refund Policy
                          </a>
                        </li>
                        <li>
                          <a href="/grievance_policy">
                            <i className="fa-solid fa-check" /> Grievance Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link p-0" href="/contact_us">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-btn">
                <ul className="list-unstyled mb-0">
                  <li>
                    <div className="sign-btn">
                      <a href="https://app.snppay.co.in/login">Login</a>
                      {/* sign-btn */}
                    </div>
                  </li>
                  <li>
                    <div className="sign-btn">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fispl.snppay"
                        target="_blank"
                      >
                        Sign Up
                      </a>
                      {/* sign-btn */}
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
            {/* container */}
          </div>
          {/* header-con */}
        </header>
      </>
    </React.Fragment>
  );
};

export default Header;
